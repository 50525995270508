.formWrapper {
    width: 100dvw;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.formCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid #66c9ba;
    width: 25rem;
}
.formCard p {
    font-size: .85rem;
}
.questionContainer {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.optionsContainer {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.quizButton {
    width: 100%;
    border: 1px solid #66c9ba;
    background: none;
    padding: 0.5rem 1.5rem;
    border-radius: 10rem;
    cursor: pointer;
    color: #66c9ba;
    font-weight: bold;
    transition: all 0.15s ease-in;
}
.quizButton:hover {
    background: #66c9ba;
    color: #ffffff;
}
.quizButton:active {
    background: #75dccc;
    border-color: #75dccc;
}
.quizButton.selected {
    background: #66c9ba;
    color: #ffffff;
}