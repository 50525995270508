.circleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.circle {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%; /* Makes it circular */
    background-color: #ddd; /* Background of the circle */
    overflow: hidden; /* To hide the excess part of the bar */
    display: flex;
    justify-content: center;
    align-items: center;
}

.bar {
    position: absolute;
    bottom: 0; /* Start filling from the bottom */
    width: 100%;
    background-color: #66c9ba; /* Bar color */
    height: 0; /* Start height at 0 */
    transition: height 2s ease-in-out; /* Smooth growth animation */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.25rem;
}

/* First wave */
.bar::before {
    content: '';
    position: absolute;
    top: -15px; /* Position the wave slightly above the bar */
    left: 0;
    width: 200%;
    height: 20px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0.8;
    border-radius: 50%; /* Curve the wave */
    animation: wave 6s infinite cubic-bezier(0.55, 0.5, 0.45, 0.5) alternate; /* Smooth, slow wave */
}

/* Second wave (randomness effect) */
.bar::after {
    content: '';
    position: absolute;
    top: -10px; /* A little lower than the first wave */
    left: 0;
    width: 200%;
    height: 25px; /* Slightly different height */
    background: rgba(255, 255, 255, 0.6);
    opacity: 0.6;
    border-radius: 50%; /* Curve the wave */
    animation: waveRandom 5s infinite cubic-bezier(0.55, 0.5, 0.45, 0.5) alternate; /* Slightly different speed */
}

/* Wavy animation for the liquid effect */
@keyframes wave {
    0% {
        transform: translateX(0) translateY(0);
    }
    50% {
        transform: translateX(-25%) translateY(3px); /* Slight vertical movement */
    }
    100% {
        transform: translateX(-50%) translateY(0);
    }
}

/* Slightly different, random wave animation */
@keyframes waveRandom {
    0% {
        transform: translateX(0) translateY(0);
    }
    50% {
        transform: translateX(-30%) translateY(4px); /* Slightly more vertical movement */
    }
    100% {
        transform: translateX(-60%) translateY(0);
    }
}