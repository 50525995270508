.questionContainer strong {
    font-size: .85rem;
}

.formContainer {
    margin: -5rem 0;
}

@media only screen and (max-width: 700px) {
    .formContainer {
        margin: -2rem 0;
    }
}