.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    height: 5rem;
    width: 100%;
}
.logo {
    height: 60px;
    cursor: pointer;
}