.factWrapper {
    position: relative;
}
.factBox {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 2px;
}
.factDropdown {
    position: absolute;
    background-color: #66c9ba;
    padding: .5rem;
    color: white;
    border-radius: .5rem;
    font-size: .85rem;
    width: 200px;
    z-index: 1000;
    box-shadow: 0 3px 6px #00000020;
}

/* Dropdown directions */
.above {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.below {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.left {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.right {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
}