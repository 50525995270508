.canWrapper {
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.canContainer {
    position: relative;
}

.can {
    height: 75dvh;
}