.button {
    border: 1px solid #66c9ba;
    background: none;
    padding: 0.5rem 1.5rem;
    border-radius: 10rem;
    cursor: pointer;
    color: #66c9ba;
    font-weight: bold;
    transition: all 0.15s ease-in;
}
.button:disabled {
    border-color: grey;
    color: grey;
    cursor: not-allowed;
}
.button:hover:not(:disabled) {
    background: #66c9ba;
    color: #ffffff;
}
.button:active:not(:disabled) {
    background: #75dccc;
    border-color: #75dccc;
    color: #ffffff;
}