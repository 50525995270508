.inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
}
.inputField {
    height: 2rem;
    width: 17rem;
    border: 1px solid #bbb;
    border-radius: .5rem;
    background: transparent;
    color: #000;
    position: relative;
}
.placeholderText{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* border: 3px solid transparent; */
    background-color: transparent;
    display: flex;
    align-items: center;
    pointer-events: none;
}
.labelText {
    font-size: .85rem;
    background-color: transparent;
    color: #000;
    transform: translate(0);
    transition: transform 0.15s ease-out, font-size 0.15s ease-out, background-color 0.2s ease-out, color 0.15s ease-out;
}
input, .placeholderText{
    font-size: .85rem;
    padding: 0 1rem;
}
.inputField:focus {
    border-color: transparent;
    outline: 2px solid #66c9ba;
    color: #000;
}
input:focus + .placeholderText .labelText{
    font-size: .75rem;
    padding-inline: .2rem;
    color: #000;
    transform: translate(0, -130%);
    border-color: #66c9ba;
    color: #66c9ba;
}
input:focus + .placeholderText .labelText, :not(input[value=""]) + .placeholderText .labelText{
    background-color: #ffffff;
    font-size: .75rem;
    padding-inline: .2rem;
    color: #444444;
    transform: translate(0, -130%);
}

input:focus + .placeholderText .labelText{
    border-color: #66c9ba;
    color: #66c9ba;
}

.visibilityToggle {
    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
}

.visibilityToggle .material-symbols-outlined {
    font-size: 16px;
    color: #000;
}