.formWrapper {
    width: 100dvw;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.formCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid #66c9ba;
    width: 25rem;
}
.formCard p {
    font-size: .85rem;
}