.sliderWrapper {
    width: 100%;
}
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: .25rem;
    background: #ddd;
    outline: none;
    border-radius: 1rem;
    transition: background 0.2s ease-in-out;
}
.slider:hover {
    background: #ccc;
}
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1rem;
    height: 1rem;
    background: #66c9ba;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: none;
}
.slider::-webkit-slider-thumb:hover {
    background: #66c9ba;
    transform: scale(1.1);
}
.slider::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #66c9ba;
    cursor: pointer;
    box-shadow: none;
}
.slider::-ms-thumb {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #66c9ba;
    cursor: pointer;
    box-shadow: none;
}